<template>
  <v-tabs right v-model="activeTabCurrently">
    <v-tab
      v-for="(tab, i) of candidateTabs"
      :key="tab.id"
      @click="changeCandidatesTab(i)"
    >
      {{ tab.total !== 0 ? tab.total : "" }} {{ tab.title }}
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CandidateTabs",
  data() {
    return {};
  },
  methods: {
    ...mapActions(["changeCandidatesTab"]),
  },
  computed: {
    ...mapGetters(["candidateTabs", "candidatesStatusActiveTab"]),
    activeTabCurrently: {
      get() {
        return this.candidatesStatusActiveTab;
      },
      set(newValue) {
        this.changeCandidatesTab(newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tab {
  text-transform: none;
}
.v-tabs-bar {
  .v-tab {
    font-size: 13px;
    font-weight: bold;
    background: #fff;
    border-radius: 10px 10px 0px 0px !important;
    box-shadow: 0px 0px 5px #acb2c136;
    padding-right: 50px;
    padding-left: 50px;
    &::before {
      border-radius: 10px 10px 0 0 !important;
    }
  }
  &::before {
    background-color: transparent;
  }
  .v-tab--active {
    background: linear-gradient(229deg, #604fe2 0%, #772276 100%);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    color: #fff;
    box-shadow: 0px 5px 15px #acb2c1;
  }
}
</style>
